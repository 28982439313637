<template>
  <data-table-component
    :headers="HEADERS_CONST"
    :items="list_sancion_prov_st"
    :total_registros="total_rows_cmp"
    @paginar="PaginateFtn"
    mobile_breakpoint="875"
    v-models:pagina="page_cmp"
    v-models:select="cant_per_page_cmp"
    table_title="Listado de sanciones"
  >
    <!-- Slot fecha inicio -->
    <template v-slot:[`item.fecha_inicio`]="{ item }">
      {{ moment(item.fecha_inicio).format("DD/MM/YYYY") || "-" }}
    </template>

    <!-- Slot estado -->
    <template v-slot:[`item.estado`]="{ item }">
      <v-chip
        label
        :color="item.estado ? 'success' : 'error'"
        text-color="white"
        v-text="item.estado ? 'activa' : 'cancelada'"
      />
    </template>

    <!-- Slot acciones -->
    <template v-slot:[`item.acciones`]="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            @click="SeeDetailFtn(item.id_sancion)"
          >
            <v-icon v-text="'mdi-eye'" />
          </v-btn>
        </template>
        <span v-text="'Ver detalle de la sanción'" />
      </v-tooltip>
    </template>
  </data-table-component>
</template>

<script>
// Importaciones globales
import DataTableComponent from "../../../components/DataTableComponent.vue";

// Importaciones de librerias
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  components: {
    DataTableComponent,
  },

  data: () => ({
    // constantes
    HEADERS_CONST: [
      {
        align: "center",
        text: "Código",
        value: "codigo",
      },
      {
        align: "center",
        text: "Origen",
        value: "numero_orden",
      },
      {
        align: "center",
        text: "Fecha de sanción",
        value: "fecha_inicio",
      },
      {
        align: "center",
        text: "Estado de sanción",
        value: "estado",
      },
      {
        align: "center",
        text: "Motivo de sanción",
        value: "descripcion_sancion",
      },
      {
        align: "center",
        text: "Tiempo (días)",
        value: "tiempo",
      },
      {
        align: "center",
        text: "Acciones",
        value: "acciones",
      },
    ],
  }),

  computed: {
    // Mapeo de estados
    ...mapState("sancionesStore", ["list_sancion_prov_st"]),
    ...mapState({
      cant_per_page_cmp: (state) =>
        state?.sancionesStore?.list_sancion_prov_pag_st?.per_page,
      page_cmp: (state) =>
        state?.sancionesStore?.list_sancion_prov_pag_st?.page,
      total_rows_cmp: (state) =>
        state?.sancionesStore?.list_sancion_prov_pag_st?.total_rows,
    }),
  },

  methods: {
    // Mapeo de mutaciones
    ...mapMutations("sancionesStore", [
      "resetListSancionProvMt",
      "resetListSancionProvPagMt",
      "SetIdSancionMt",
      "SetModalSancionProvMt",
    ]),

    // Mapeo de acciones
    ...mapActions("sancionesStore", [
      "FetchDetailSancionAc",
      "FetchProvListSancionAc",
    ]),

    SeeDetailFtn(id_sancion_param) {
      this.SetIdSancionMt(id_sancion_param);
      this.SetModalSancionProvMt(true);
      this.FetchDetailSancionAc();
    },

    // Funciones asincronas
    //  Funcion que recibe los filtros de paginacion y realiza la peticion de listado de proveedores sancionados
    async PaginateFtn(filters_param_ext) {
      const { cantidad_por_pagina, pagina } = filters_param_ext;

      const id_proveedor_param = this.$route.params.id;

      const filters_param = {
        page: pagina,
        per_page: cantidad_por_pagina,
      };

      /*
      WARNING: 
      la forma en que el argumento es enviado a la accion es importante, 
      ya que la accion no puede recibir multiples argumentos,
      por lo que se debe enviar un objeto con los argumentos y estos deben ser desestructurados en la accion
      con el mismo nombre de las propiedades del objeto
      */
      await this.FetchProvListSancionAc({ id_proveedor_param, filters_param });
    },
  },

  beforeDestroy() {
    //  Reseteo de listado de proveedores sancionados y paginacion
    this.resetListSancionProvMt();
    this.resetListSancionProvPagMt();
  },
};
</script>
<style lang="scss" scoped>
:deep(.v-chip) {
  width: -webkit-fill-available !important;
  display: flex !important;
  justify-content: center !important;
}
</style>
