<template>
  <div class="mt-9 mt-md-0">
    <div class="d-flex justify-center">
      <!-- <v-btn-toggle
        :key="i"
        group
        v-for="(item, i) in OPTIONS_TAB_CONST"
        v-model="option_btn_var"
      >
        <v-btn
          :value="item.value"
          class="text-capitalize"
          outlined
          v-text="item.text"
        />
      </v-btn-toggle> -->
    </div>
    <v-tabs-items :value="option_btn_var" style="width: 100%">
      <div v-for="(item, index) in OPTIONS_TAB_CONST" :key="index">
        <v-tab-item>
          <component class="py-6 px-3" :is="item.content" />
        </v-tab-item>
      </div>
    </v-tabs-items>
  </div>
</template>

<script>
// importaciones local
import TableSancionesComponent from "./TableSancionesComponent.vue";

export default {
  components: {
    TableSancionesComponent,
  },

  data: () => ({
    // constantes
    OPTIONS_TAB_CONST: [
      {
        content: TableSancionesComponent,
        text: "Sanciones",
        value: 0,
      },
    ],

    // variables
    option_btn_var: 0, // al iniciar el componente se muestra la primera opcion, si se desea mostrar otra opcion se debe cambiar el valor de esta variable a null
  }),
};
</script>

<style lang="scss" scoped>
:deep(.v-btn-toggle--group > .v-btn.v-btn--active) {
  border: thin solid #404a7a;
  color: #404a7a !important;
}
</style>
