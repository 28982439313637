<template>
  <section>
    <!-- Titulo -->
    <!--  Componente dinamico con props title del tipo string  -->
    <title-header-component title="Sanciones" />

    <!-- Información general -->
    <!--  Componente dinamico que contiene la informacion del proveedor, tiempo de sancion y monto total de multas -->
    <info-general-component />

    <!-- Detalles de la sancion -->
    <!--  Componente dinamico que contiene las tablas de sanciones y multas -->
    <container-details-component />

    <!-- modal de la sancion -->
    <!--  Componente dinamico que contiene el modal de la sancion -->
    <dialog-item-detail-component />

    <!-- footer -->
    <footer
      tile
      class="py-4 mt-8 d-flex flex-column flex-sm-row"
      color="bgMinsal"
    >
      <!-- boton que redirecciona a la vista anterior -->
      <v-btn
        class="button-extra text-capitalize mt-4 mt-sm-0"
        color="secondary"
        outlined
        @click="$router.back()"
      >
        Volver
      </v-btn>
    </footer>
  </section>
</template>

<script>
// Importaciones locales
import TitleHeaderComponent from "./components/TitleHeaderComponent.vue";
import InfoGeneralComponent from "./components/InfoGeneralComponent.vue";
import ContainerDetailsComponent from "./components/ContainerDetailsComponent.vue";
import DialogItemDetailComponent from "./components/DialogItemDetailComponent.vue";

// Importaciones de librerias
import { mapActions, mapMutations } from "vuex";

export default {
  components: {
    TitleHeaderComponent,
    InfoGeneralComponent,
    ContainerDetailsComponent,
    DialogItemDetailComponent,
  },

  methods: {
    // Mapeo de acciones
    ...mapActions("sancionesStore", [
      "FetchDetailProvSancionAc",
      "FetchProvListSancionAc",
    ]),

    //  Funcion que se ejecuta al crear el componente, se encarga de llamar a las funciones que cargan la informacion de la sancion y las sanciones del proveedor
    async InitFtn() {
      const id_proveedor_param = Number(this.$route.params.id);

      //  Llamada a la funcion FetchDetailProvSancionAc al crear el componente para cargar informacion de la sancion del proveedor
      await this.FetchDetailProvSancionAc(id_proveedor_param);

      //  Llamada a la funcion FetchProvListSancionAc al crear el componente para cargar informacion de las sanciones del proveedor
      this.FetchProvListSancionAc({ id_proveedor_param });
    },
  },

  created() {
    this.InitFtn();
  },
};
</script>
<!-- Vista de detalle de sanciones de proveedor -->
