<template>
  <v-row>
    <v-col cols="12" md="6" class="d-flex justify-center justify-md-start">
      <!-- 
        HACK: el componente InfoProductoComponent posee problemas de carga de datos, 
        por lo que se oculta el componente hasta que se cargue la información del proveedor, para evitar errores de carga de datos.

        por favor, revisar el componente InfoProductoComponent.vue y corregir el problema de carga de datos.
       -->
      <template v-if="selectedProvider">
        <!--  componente dinamico que muestra la información del proveedor, asi como su rating -->
        <info-producto-component hideMount />
      </template>
    </v-col>

    <v-col
      cols="12"
      md="6"
      class="d-flex justify-center align-center flex-column flex-sm-row justify-sm-space-around justify-md-end"
    >
      <div
        class="d-flex flex-column text-center mb-3 mb-sm-0 secondary--text mr-md-6"
      >
        <span class="text-h4" v-text="tiempoSancionado" />
        <span v-text="'Tiempo sancionado'" />
      </div>
    </v-col>
  </v-row>
</template>

<script>
// importaciones global
import InfoProductoComponent from "../../../components/InfoProductoComponent.vue";

// importaciones de librerias
import { mapState, mapMutations } from "vuex";

export default {
  components: {
    InfoProductoComponent,
  },

  computed: {
    // mapeo de estados
    ...mapState("agregarInsumo", ["selectedProvider"]),
    ...mapState("sancionesStore", ["detail_prov_st", "list_sancion_prov_st"]),

    tiempoSancionado() {
      const dias_sancion = this.list_sancion_prov_st.reduce((acc, item) => {
        return acc + item.tiempo;
      }, 0);

      if (dias_sancion > 1) {
        return `${dias_sancion} días`;
      } else {
        return `${dias_sancion} día`;
      }
    },
  },

  methods: {
    // mapeo de mutaciones
    ...mapMutations("agregarInsumo", ["setCurrentProvider", "setProviderInfo"]),
    ...mapMutations("sancionesStore", ["resetDetailProvMt"]),

    //  funcion que inicializa el componente y carga la información del proveedor ( en el componente InfoProductoComponent )
    InitFtn() {
      this.setCurrentProvider(this.$route.params.id);
      this.setProviderInfo(this.detail_prov_st);
    },
  },

  // HACK: al tener problemas de perdida de datos al recargar la pagina, se agrega un watcher para que se vuelva a cargar la información del proveedor
  watch: {
    detail_prov_st() {
      this.InitFtn();
    },
  },

  created() {
    this.InitFtn();
  },

  beforeDestroy() {
    //  se resetea informacion del proveedor
    this.resetDetailProvMt();
  },
};
</script>
