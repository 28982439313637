<template>
  <v-row>
    <v-col cols="12" md="4">
      <v-img v-if="providerLogo" max-height="242" max-width="242" :src="providerLogo" />
      <v-icon v-else color="primary" size="242px"> mdi-image-outline </v-icon>
    </v-col>
    <v-col cols="12" md="8">
      <v-row>
        <v-col cols="12" md="6">
          <p class="font-weight-medium primary--text mb-0">Nombre del proveedor:</p>
          <p class="text-subtitle-1 font-weight-bold primary--text">
            {{ providerData?.nombre || 'No asignado' }}
          </p>
          <p class="font-weight-medium primary--text mb-0">Tipo de persona</p>
          <p class="text-subtitle-1 font-weight-bold primary--text">
            {{ providerData?.tipo_contribuyente || 'No asignado' }}
          </p>
          <p class="font-weight-medium primary--text mb-0">DUI:</p>
          <p class="text-subtitle-1 font-weight-bold primary--text">
            {{
              providerData?.documentos?.find(
                target => target?.id_tipo_documento == 1
              )?.numero_documento ||
              providerData?.documentos?.find(
                target => target?.TipoDocumento?.id == 1
              )?.numero_documento ||
              'No asignado'
            }}
          </p>
          <p class="font-weight-medium primary--text mb-0">NIT:</p>
          <p class="text-subtitle-1 font-weight-bold primary--text">
            {{
              providerData?.documentos?.find(
                target => target?.id_tipo_documento == 2
              )?.numero_documento ||
              providerData?.documentos?.find(
                target => target?.TipoDocumento?.id == 2
              )?.numero_documento ||
              'No asignado'
            }}
          </p>
          <p class="font-weight-medium primary--text mb-0">Teléfono:</p>
          <p class="text-subtitle-1 font-weight-bold primary--text">
            {{ providerData?.area || 'No asignado' }}
            {{ providerData?.telefono || 'No asignado' }}
          </p>
          <p class="font-weight-medium primary--text mb-0">Correo electrónico:</p>
          <p class="text-subtitle-1 font-weight-bold primary--text">
            {{ providerData?.email || 'No asignado' }}
          </p>
        </v-col>
        <v-col cols="12" md="6">
          <p class="font-weight-medium primary--text mb-0">Nombre comercial:</p>
          <p class="text-subtitle-1 font-weight-bold primary--text">
            {{ providerData?.nombre_comercial || 'No asignado' }}
          </p>
          <p class="font-weight-medium primary--text mb-0">Género:</p>
          <p class="text-subtitle-1 font-weight-bold primary--text">
            {{ providerData?.genero || 'No asignado' }}
          </p>
          <p class="font-weight-medium primary--text mb-0">Sitio web:</p>
          <p class="text-subtitle-1 font-weight-bold primary--text">
            {{ providerData?.sitio_web || 'No asignado' }}
          </p>
          <p class="font-weight-medium primary--text mb-0">
            Clasificación de la empresa:
          </p>
          <p class="text-subtitle-1 font-weight-bold primary--text">
            {{ providerData?.clasificacion_empresa || 'No asignado' }}
          </p>
          <p class="font-weight-medium primary--text mb-0">Dirección:</p>
          <p class="text-subtitle-1 font-weight-bold primary--text">
            {{ getAddress(providerData?.direccion) || 'No asignado' }}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <p class="font-weight-medium primary--text mb-0">Giros empresariales:</p>
          <p class="text-subtitle-1 font-weight-bold primary--text">
            {{ getGirosEmpresariales(providerData?.giro_empresarial) || '-' }}
          </p>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'InformacionProveedorComponent',
  props: {
    providerData: {
      type: Object,
      default: () => { }
    }
  },
  computed: {
    ...mapState('proveedores', ['providerLogo']),
    ...mapState('agregarInsumo', ['selectedProvider'])
  },
  methods: {
    ...mapActions('proveedores', ['getProviderLogo']),

    getAddress(data) {
      let address = `${data?.pais}${data?.departamento ? ', ' + data?.departamento : ''
        }${data?.municipio ? ', ' + this.getMunicipioName(data?.municipio) : ''
        }, ${data?.detalle_direccion}`
      return address
    },
    getMunicipioName(data) {
      let name = data?.split(' ')
      name?.pop()
      return name?.join(' ')
    },
    getGirosEmpresariales(data) {
      return data?.map(target => target?.nombre)?.join(', ')
    },
  },
  watch: {
    providerData: function (v) {
      if (this.selectedProvider != null) {
        this.getProviderLogo(this.selectedProvider)
      } else if (this.providerData?.id) {
        this.getProviderLogo(this.providerData?.id)
      }
    }
  },
  created() {
    if (this.selectedProvider != null) {
      this.getProviderLogo(this.selectedProvider)
    } else if (this.providerData?.id) {
      this.getProviderLogo(this.providerData?.id)
    }
  }
}
</script>
