<template>
  <div class="my-6 d-block text-center text-sm-left">
    <span class="text-h5 secondary--text" v-text="title" />
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
};
</script>
