<template>
  <v-dialog v-model="modal_sancion_prov_st" persistent>
    <v-card class="pa-4">
      <v-row>
        <!-- corner button -->
        <v-col cols="1">
          <v-btn
            :class="$vuetify.breakpoint.lgAndUp ? 'closeBtn' : 'ma-0'"
            @click="close"
            icon
            x-large
          >
            <v-icon v-text="'mdi-close'" color="secondary" />
          </v-btn>
        </v-col>

        <!-- content -->
        <v-col cols="11" class="mt-4">
          <v-row class="pt-1">
            <v-col cols="12" md="6" class="d-flex align-center pl-0">
              <span
                class="text-h5 secondary--text"
                v-text="detail_sancion_st?.codigo"
              />
            </v-col>

            <v-col cols="12" md="6" class="d-flex justify-end">
              <v-btn
                v-if="false"
                class="red--text text-no-style"
                outlined
                v-text="'Cancelar sanción'"
              />
            </v-col>
          </v-row>

          <v-row class="d-flex flex-column">
            <div class="d-flex flex-column mb-2">
              <span class="text-caption" v-text="'Fecha de sanción'" />
              <span
                v-text="
                  moment(detail_sancion_st?.fecha_inicio).format('DD/MM/YYYY')
                "
              />
            </div>

            <div class="d-flex flex-column my-2">
              <span class="text-caption" v-text="'Sanción aplicada'" />
              <span v-text="detail_sancion_st?.descripcion_sancion" />
            </div>

            <div class="d-flex flex-column my-2">
              <span class="text-caption" v-text="'Motivo de sanción'" />
              <p v-text="detail_sancion_st?.observaciones" />
            </div>

            <div class="d-flex flex-column my-2">
              <span class="text-caption" v-text="'Tiempo sancionado'" />
              <span v-text="`${detail_sancion_st?.tiempo} días`" />
            </div>
          </v-row>

          <!-- button to close -->
          <v-row class="mb-3 mt-12">
            <v-btn
              @click="close"
              class="secondary--text text-capitalize"
              text
              v-text="'volver'"
            />
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
// importacion de librerias
import { mapState, mapMutations } from "vuex";

export default {
  computed: {
    // mapeo de estados
    ...mapState("sancionesStore", [
      "detail_sancion_st",
      "modal_sancion_prov_st",
    ]),
  },

  methods: {
    // mapeo de mutaciones
    ...mapMutations("sancionesStore", [
      "resetDetailSancionMt",
      "resetIdSancionMt",
      "SetModalSancionProvMt",
    ]),

    //  funcion que cierra el modal, resetea el detalle de la sancion y el id de la sancion
    close() {
      this.SetModalSancionProvMt(false);
      this.resetDetailSancionMt();
      this.resetIdSancionMt();
    },
  },
};
</script>

<style scoped>
.closeBtn {
  margin: 20px 30px;
}

/*  media query para modificar margin de button corner */ 
@media (max-width: 1700px) {
  .closeBtn {
    margin: 20px 20px;
  }
}
</style>
